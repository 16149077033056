<template>
    <div class="column-list-box">
        <div class="column-box">
            <img class="column-title" :src="columntPublishTitle" />
            <div class="column" ref="column">
                <div class="column-list">
                    <div class="column-item" v-for="(column, i) of columnPublishs" :key="i"
                        @click="chooseColumn(column)">
                        <i class="column-cover" :style="{ backgroundImage: 'url(' + column.config.cover + ')' }"></i>
                        <div class="column-name">{{ column.name }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="column-box">
            <img class="column-title" :src="columntEducationTitle" />
            <div class="column" ref="column">
                <div class="column-list">
                    <div class="column-item" v-for="(column, i) of columnEducations" :key="i"
                        @click="chooseColumn(column)">
                        <i class="column-cover" :style="{ backgroundImage: 'url(' + column.config.cover + ')' }"></i>
                        <div class="column-name">{{ column.name }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "colunm-list",
    components: {},
    data() {
        return {
            columntPublishTitle: "https://img.lessonplan.cn/IMG/yuanxi/images/home/column-publish.png",
            columntEducationTitle: "https://img.lessonplan.cn/IMG/yuanxi/images/home/column-education.png",

        }
    },
    computed: {
        ...mapState({
            columnPublishs: (state) => state.columnList.filter((item) => item.type == "publish") || [],
            columnEducations: (state) => state.columnList.filter((item) => item.type == "education") || [],
        })
    },
    mounted() {

    },
    methods: {
        //选择分栏
        chooseColumn(column) {
            const { pk_digital_column_id, config } = column;

            if (config.website) {
                return window.open(config.website);
            }
            else {
                window.open(`https://yuanxi-1.lessonplan.cn/column/${pk_digital_column_id}`)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.column-list-box {
    .column-box {
        margin: 20px auto;
        width: 100%;
        margin-bottom: 100px;
        text-align: center;

        .column-title {
            padding-bottom: 20px;
        }

        .column-subtitle {
            font-size: 18px;
            margin-bottom: 30px;
        }

        .column {
            overflow-x: auto;
            background-color: #f5f5f550;
            padding: 40px 20px;

            .column-list {
                max-width: 1100px;
                margin: 0 auto;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                margin: 0 auto;

                .column-item {
                    width: calc(100%/5 - 20px);
                    background: transparent;
                    color: #666666;
                    margin: 10px;
                    transition: all .3s ease;
                    cursor: pointer;
                    border-radius: 5px;

                    @media screen and (max-width: 1000px) {
                        & {
                            width: calc(100%/4 - 20px);
                        }
                    }

                    &:hover {
                        box-shadow: 0 0 10px #8fa0d3b8;
                        transform: translateY(-15px);
                        -webkit-transform: translateY(-15px);

                        .column-cover {
                            &::before {
                                background-color: rgba(0, 0, 0, 0);
                            }
                        }
                    }

                    .column-cover {
                        display: block;
                        height: 140px;
                        width: 100%;
                        background-size: cover;
                        background-position: center;
                        border-radius: 5px 5px 0 0;
                        background-color: $theme-color;
                        position: relative;

                        &::before {
                            transition: all .3s ease;
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background-color: rgba(0, 0, 255, 0.2);
                            border-radius: 5px 5px 0 0;
                            /* 这里的颜色代码表示浅蓝色的半透明效果，可以根据需要进行调整 */
                        }
                    }

                    .column-name {
                        text-align: center;
                        padding: 20px;
                        border-radius: 0 0 5px 5px;
                        font-size: 15px;
                        @include multi-ellipsis(2);
                        height: 88px;
                        background-color: #ffffff;
                    }
                }
            }
        }

    }
}
</style>