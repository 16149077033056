<template>
  <div>
    <div class="q-q">
      <div class="q-index">[{{ item2.type }}] </div>
      <div class="q-html fillintheblank" v-html="item2.question"></div>
    </div>
    <div class="opt-list">
      <div class="completion-box">
        <div v-for="(item3, key, index3) in item2.answer" :key="index3"
          style="align-items: center;display: flex;  margin: 6px 10px; ">
          填空区{{ index3 + 1 }}:
          <span>{{ stuAnswer[key][0] }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    stuAnswer: {
      type: Object,
      required: true
    },
    item2: {
      type: Object,
      required: true
    },
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
:deep(.fillintheblank)
{
  span
  {
    margin: 0 5px;
    padding: 0 5px;
    opacity: 0.7;
    text-decoration: underline;
    cursor: pointer;
    color: #fff;
  }

  span::before
  {
    content: "(  ";
    color: #fff;
  }

  span::after
  {
    content: "  )";
    color: #fff;
  }
}
</style>