<template>
  <div class="portraits-info">
    <div class="book-box">
      <div class="book-page book-left" @click="bookScroll('left')">
        <svg class="svg-icon">
          <use xlink:href="#icon-last" />
        </svg>
      </div>
      <div class="book" v-if="!phoneMode">
        <div class="book-item"
          :class="{ selected: currentBook && currentBook?.pk_store_book_id == book.pk_store_book_id }"
          v-for="(book, i) of storeBooks" @click="chooseBook(i)">{{ book.name }}
        </div>
      </div>
      <div class="book current-book" v-else>
        <div class="book-item selected">{{ currentBook.name }}</div>
      </div>
      <div class="book-page book-right" @click="bookScroll('right')">
        <svg class="svg-icon">
          <use xlink:href="#icon-next" />
        </svg>
      </div>
    </div>
    <portraits-content :currentBook="currentBook" v-if="currentBook"></portraits-content>

    <div class="empty-box" v-if="!loading && storeBooks.length == 0">
      <img :src="empty_image" alt />
      <div>暂无数据</div>
    </div>
  </div>
</template>
<script>
import PortraitsContent from "./portraits-content.vue";
import portraitsWrongBook from "./portraits-wrong-book.vue";

export default {
  components: {
    PortraitsContent,
    portraitsWrongBook
  },
  data() {
    return {
      userId: "",

      storeBooks: [],
      currentBook: null,
      behaviorData: null,

      loading: true,
      empty_image: "https://img.lessonplan.cn/IMG/yuanxi/images/empty.png",
    };
  },
  async mounted() {
    const { userInfo, storeBooks, phoneMode } = this.$store.state;
    this.storeBooks = storeBooks.filter((item) => item.isPaid && !item.single_url);
    this.userId = userInfo.PK_UserGuid;
    this.phoneMode = phoneMode;

    if (this.storeBooks.length == 0) return this.loading = false;

    this.chooseBook(0)
  },
  methods: {
    //选择书本
    chooseBook(index) {
      this.currentBook = this.storeBooks[index];
    },
    //滚动条移动
    bookScroll(type) {
      if (!this.phoneMode) {
        const scroll = $(".book").scrollLeft();
        const width = $(".book").width() - 20;

        if (type == "left") $(".book").animate({ scrollLeft: scroll - width }, 300);
        else if (type == "right") $(".book").animate({ scrollLeft: scroll + width }, 300);
      }
      else {
        let index = this.storeBooks.findIndex(item => item.pk_store_book_id == this.currentBook.pk_store_book_id);
        if (type == "left") index--;
        else if (type == "right") index++;
        if (index < 0) index = this.storeBooks.length - 1;
        else if (index >= this.storeBooks.length) index = 0;
        this.chooseBook(index);
      }
    }
  },
};
</script>

<style scoped lang="scss">
.portraits-info {
  max-width: 1000px;
  padding: 5px;
  margin: 0 auto;

  .book-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin: 30px 0;

    .book {
      display: flex;
      overflow-y: hidden;
      white-space: nowrap;
      width: calc(100% - 100px);

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }

      &.current-book {
        overflow: hidden;
        justify-content: center;
      }

      .book-item {
        padding: 0px 15px;
        margin: 0px 10px;
        font-size: 17px;
        height: 40px;
        display: flex;
        align-items: center;
        border-radius: 7px;
        transition: all 0.3s ease;
        cursor: pointer;
        position: relative;
        color: #ffffff85;
        max-width: 100%;
        @include ellipsis();

        &.selected {
          color: $text-color;
          background-color: #bbbbbb4f;
        }

        .book-icon {
          height: 40px;
          width: 40px;
          opacity: 0.5;
          transition: all 0.3s ease;
          position: absolute;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }

    .book-page {
      background-color: #bbbbbb4f;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      cursor: pointer;
      transition: all 0.3s ease;

      &.book-left {
        left: 30px;
      }

      &.book-right {
        right: 30px;
      }

      &:hover {
        background-color: $theme-color+'cf';
      }
    }
  }
}
</style>
