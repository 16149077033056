import { SPM_API, CODE_API, CLIENT_ACCOUNT, ROSTER_API } from '../baseURL'
import axios from '../config'

const schoolGuid = '96e873b3-ac6f-02f7-4c78-f2f7485a7145';

export default {
  //获取类型列表
  getDigitalBookType() {
    return axios.get(`${SPM_API}/digitalBookType/digitalBookType?fk_school_id=${schoolGuid}`)
  },
  //获取数字教材列表
  getDigitalBookList() {
    return axios.get(`${SPM_API}/digitalBook/list?fk_school_id=${schoolGuid}&&hasPacket=1`)
  },
  //获取数字教材用户
  getDigitalBookUser(fk_user_id) {
    return axios.get(`${SPM_API}/digitalUser/digitalUser?fk_school_id=${schoolGuid}&fk_user_id=${fk_user_id}`)
  },
  //设置用户操作记录
  setDigitalBookUser(data) {
    return axios.put(`${SPM_API}/digitalUser/digitalUser`, data)
  },
  //获取数字教材栏目
  getDigitalBookColumn() {
    return axios.get(`${SPM_API}/digitalColumn/list?fk_school_id=${schoolGuid}`)
  },

  //////////////////////////////////////其他
  //获取推送互动
  getInduction(classGuid) {
    return axios.get(`${CODE_API}/apiv2/induction?FK_ClassGuid=${classGuid}`)
  },
  //加入班级list
  joinList(userGuid) {
    return axios.get(`${CLIENT_ACCOUNT}/user/joined/${userGuid}`)
  },
  //主键获取班级列表
  getClassByPKGuid(classGuidList) {
    return axios.post(`${ROSTER_API}/class/classByPKGuid`, { classGuidList })
  },
  //获取学生成绩
  getScoreList(FK_ClassGuid, PK_UserGuid) {
    return axios.post(`${ROSTER_API}/score/scoreList`, { FK_ClassGuid, PK_UserGuid })
  },
  //获取学生画像
  getPortraits(FK_ClassGuid, userGuid) {
    return axios.post(`${ROSTER_API}/score/portraits`, { FK_ClassGuid, userGuid })
  },
}