<template>
  <div class="achievement-box fadeIn">
    <a-spin class="list-spin" v-if="loading" tip="加载中..."></a-spin>
    <div class="land-box">
      <!-- 没有任何勋章 -->
      <div class="achievement-list" v-if="show_class.length == 0">
        <div class="achievement-item notshow" v-for="item of allAchievementList" @click="showAchievement = item">
          <i class="achievement-cover i-icon" :style="{ backgroundImage: 'url(' + item.extension_info?.cover + '!260x390)' }"></i>
        </div>
      </div>
      <!-- 按班级分 -->
      <div class="class-list" v-else>
        <div class="class-item" v-for="sc of show_class">
          <div class="class-name-box">
            <div class="class-name">{{ sc.class_name }}</div>
            <div class="level-box" v-if="sc.levelInfo">
              <div class="level-text">学习等级：</div>
              <div class="user-level">
                <span>LV.{{ sc.levelInfo?.value || 0 }}</span>
                <a-progress class="level-progress" strokeColor="$theme-color" :percent="sc.levelInfo?.EXPProcess"
                  :strokeWidth="5" :show-info="false" />
                <span class="level-value" v-if="sc.levelInfo?.upgradeEXP">{{ sc.levelInfo?.EXP }}/{{
                  sc.levelInfo?.upgradeEXP
                }}</span>
              </div>
            </div>
          </div>
          <div class="achievement-list">
            <div class="achievement-item " v-for="item of sc.achievement" @click="showAchievement = item">
              <i class="achievement-cover i-icon" :style="{ backgroundImage: 'url(' + item.extension_info?.cover + '!260x390)' }"></i>
            </div>
          </div>
          <div class="certificat-list">
            <img class="certificat-item" :src="item.url" alt v-for="item in sc.certificatList"
              @click="certificat = item.url" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <show_achievement :showAchievement="showAchievement" type="show" @closeBox="showAchievement = null"
    v-if="showAchievement"></show_achievement>
</template>

<script>
import show_achievement from "./show_achievement/show_achievement.vue";

export default {
  name: "achievement_list",
  components: {
    show_achievement
  },
  data() {
    return {
      loading: true,
      showAchievement: null,
      certificat: null,

      show_class: [],
      allAchievementList: [],
      achievementList: [],

      certificatList: [],
      certificat: null,
    }
  },

  async mounted() {
    this.getStudentRecord();
  },
  methods: {
    //查询学生所有记录
    async getStudentRecord() {
      let res = await this.$api.unifydata.getStudentRecord();
      if (res.statusCode != 200) return this.$message.warning(res.message);
      let list = res.data || [];
      let { storeBooks, allAchievementList } = this.$store.state;
      this.allAchievementList = allAchievementList;
      let show_class = [];
      storeBooks.forEach(item => {
        let myItem = list.find(litem => litem.classId == item.fk_class_id);
        if (myItem) {
          show_class.push({
            class_name: item.title,
            achievement: myItem.achievement || [],
            certificatList: myItem.certificat_list || [],
            levelInfo: myItem.levelInfo
          })
        }
      })
      this.show_class = show_class;

      this.loading = false;
      setTimeout(() => { $('.achievement-item').height($('.achievement-item').width()) }, 100);
    }
  },
}

</script>

<style scoped lang="scss">
@import "./achievement_list.scss";
</style>
