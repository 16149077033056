<template>
  <div class="column fadeIn" v-if="!loading" :style="{ backgroundImage: 'none' }">
    <template v-if="column">
      <div class="title-box">
        <div class="sub-title" :style="{ color: column.config.textColor }">{{ column.name }}</div>
        <a-input-search class="input-search" v-model:value="keyword" placeholder="请输入教材名称搜索" @search="onSearch"
          :style="{ color: column.config.textColor }" />
      </div>
      <div class="course">
        <div class="course-box">
          <div class="course-item-box" v-for="book of storeBooks" @click="routerCourse(book)">
            <div class="course-item" :style="{ backgroundImage: 'url(' + book.extension_info?.cover + '!260x390)' }">
              <div class="course-top" @click.stop v-if="!phoneMode">
                <a-tooltip title="详情">
                  <div class="svg-box info" @click="routerCourse(book)">
                    <svg class="icon-svg">
                      <use xlink:href="#icon-info"></use>
                    </svg>
                  </div>
                </a-tooltip>
              </div>
            </div>
            <div class="course-name">{{ book.name }}</div>
          </div>
          <emptyBox warning="Sorry,暂无此类教材" v-if="storeBooks.length == 0"></emptyBox>
        </div>
      </div>
      <img class="aboutus" :src="column.config.aboutus" alt />
    </template>
    <emptyBox warning="未找到相应栏目" v-if="!column"></emptyBox>
  </div>
</template>

<script>
import foot from '@/component/public/foot/foot.vue';
import navroot from "@/component/public/nav/nav.vue";
import emptyBox from "@/component/public/empty-box/empty-box.vue";

export default {
  components: {
    foot,
    navroot,
    emptyBox
  },
  data() {
    return {
      column: null,

      allStoreBooks: [],
      storeBooks: [],
      empty_image: 'https://img.lessonplan.cn/IMG/yuanxi/images/empty.png',
      keyword: '',

      loading: true,
      phoneMode: false
    }
  },
  mounted() {
    $('#content').animate({ scrollTop: 0 }, 300);
    const digitalColumnId = this.$route.params.id;
    const { storeBooks, phoneMode } = this.$store.state;
    this.allStoreBooks = storeBooks.filter(item => item.column_list.indexOf(digitalColumnId) > -1);

    if (this.column.pk_digital_column_id == '3c4ac240-adf1-11ee-ac3c-fbf1324ee8d7') document.title = `数字教材`;
    else document.title = `${this.column.name} - 新一代数字教材平台`;

    this.phoneMode = phoneMode;

    this.$store.subscribe((res) => {
      if (!res) return;
      let { type, payload } = res;
      if (type == 'routerAbout' && payload) {
        if ($(`.aboutus`).offset()) {
          let scrollTop = $(`#content`).scrollTop() + $(`.aboutus`).offset().top - 30;
          $('#content').animate({ scrollTop }, 300);
        }
      }
    })

    this.onSearch();
    this.setStyle();
  },
  methods: {
    //关键词搜索
    onSearch() {
      let allStoreBooks = this.allStoreBooks;
      this.storeBooks = allStoreBooks.filter(item => item.title.indexOf(this.keyword) > -1);
      this.loading = false;
    },
    //设置style
    setStyle() {
      let column = this.column;
      if (!column) return;

      this.$store.commit("column", column);
      $('#content').css({ background: column.config.backgroundColor });

      // this.$nextTick(() => {
      //   $('.input-search input').css({ color: column.config.textColor });
      //   $('.input-search span').css({ color: column.config.textColor });
      // })
    },

    //跳转
    routerCourse(item) {
      this.$router.push(`/course-info/${item.pk_store_book_id}?columnId=${this.column.pk_digital_column_id}`);
    },
  },
  beforeUnmount() {
    document.title = `元习数字阅读`;
    $('#content').css({ background: `#213E8C` });
    this.$store.commit("column", null);
  },
};
</script>

<style scoped lang="scss">
@import "./column-info.scss";
</style>