<template>
  <!-- 我的数字教材 -->
  <div class="person-myorder fadeIn">
    <emptyBox warning="Sorry,您还没有任何订单" v-if="!loading && orderList.length == 0"></emptyBox>
    <div class="table-head" v-if="orderList.length > 0">
      <div class="left-box">已购教材</div>
      <div class="right-box">
        <div>交易时间</div>
        <div>订单总额</div>
        <div>实际支付</div>
        <div>订单状态</div>
        <div v-if="!phoneMode"></div>
      </div>
    </div>
    <div class="table-order" v-if="orderList.length > 0">
      <div class="order-item" v-for="(item, index) in orderList" :key="index">
        <div class="left-box">
          <i class="goods-cover" :style="{ backgroundImage: 'url(' + item.extension_info?.cover + '!260x390)' }"></i>
          <div class="order-title">
            <div>数字教材：{{ item.name }}</div>
            <div class="order-num">订单编号：{{ item.pk_payorderGuid }}</div>
          </div>
        </div>
        <div class="right-box">
          <div>{{ item.payTime || '' }}</div>
          <div>
            <span v-if="item.is_unique_book">内测版本</span>
            <span v-else> {{ '￥' + item.price || 0 }}</span>
          </div>
          <div>{{ item.payMoney != null ? '￥' + item.payMoney : '' }}</div>
          <div>{{ item.isPaid == 1 ? '交易成功' : '未支付' }}</div>
          <div v-if="!phoneMode">
            <a-button type="primary" v-if="item.isPaid && item.payMoney > 0"
              @click="invoiceOrder = item">申请开发票</a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <apply-for-invoice v-if="invoiceOrder" :payorder="invoiceOrder" @close="invoiceOrder = undefined"></apply-for-invoice>
</template>
<script>
import emptyBox from "@/component/public/empty-box/empty-box.vue";
import * as moment from "moment";
import applyForInvoice from "./apply-for-invoice.vue";
import { mapState } from "vuex";

export default {
  components: {
    emptyBox,
    applyForInvoice
  },
  data() {
    return {
      orderList: [],
      loading: true,
      invoiceOrder: undefined,
    };
  },
  computed: {
    ...mapState({
      phoneMode: (state) => state.phoneMode || false,
    })
  },
  async mounted() {
    this.getData();
  },
  methods: {
    //获取数字教材
    async getData() {
      const state = this.$store.state;
      this.user = state.userInfo;
      this.allStoreBooks = state.storeBooks;
      this.getOrderList();
    },
    //获取
    async getOrderList() {
      const res = await this.$api.payorder.getOrderList(this.user.PK_UserGuid);
      if (res.statusCode != 200) return;
      const orderList = res.data;
      const showOrderList = [];
      orderList.forEach(item => {
        item.payTime = item.payTime ? moment(item.payTime).format('YYYY-MM-DD HH:mm:ss').toString() : '';
        const book = this.allStoreBooks.find(book => book.pk_store_book_id === item.fk_goodsGuid);

        if (book) {
          item.extension_info = book.extension_info;
          item.name = book.name;
          item.price = book.price;
          showOrderList.push(item);
        }
      })
      this.orderList = showOrderList;
      //this.invoiceOrder = showOrderList.find(item => item.isPaid && item.payMoney > 0);

      this.loading = false;
    }
  }
};
</script>

<style scoped lang="scss">
@import "./buy-order.scss";
</style>