<template>
  <div class="achievement-box fadeIn">
    <a-spin class="list-spin" v-if="loading" tip="加载中..."></a-spin>
    <div class="land-box land-certificat">
      <emptyBox warning="Sorry,您还没有任何证书" v-if="!loading && certificatList.length == 0"></emptyBox>
      <div class="certificat-list">
        <img class="certificat-item" :src="item.url" alt v-for="item in certificatList" @click="certificat = item.url" />
      </div>
    </div>
  </div>
  <show_certificat :certificat="certificat" @closeBox="certificat = null" v-if="certificat"></show_certificat>
</template>

<script>
import show_certificat from "./show_certificat/show_certificat.vue";
import emptyBox from "@/component/public/empty-box/empty-box.vue";

export default {
  name: "certificat_list",
  components: {
    show_certificat,
    emptyBox
  },
  data() {
    return {
      loading: true,
      certificat: null,

      certificatList: []
    }
  },

  async mounted() {
    this.getStudentRecord();
  },
  methods: {
    //查询学生所有记录
    async getStudentRecord() {
      let res = await this.$api.unifydata.getStudentRecord();
      if (res.statusCode != 200) return this.$message.warning(res.message);
      let list = res.data || [];
      //找到当前班级
      let { storeBooks } = this.$store.state;
      let myList = [];
      storeBooks.forEach(item => {
        let myItem = list.find(litem => litem.classId == item.fk_class_id);
        if (myItem) myList.push(myItem);
      })
      //找到证书
      let certificatList = [];
      myList.forEach(item => {
        let certificat_list = item.certificat_list || [];
        certificatList = certificatList.concat(certificat_list);
      })
      this.certificatList = certificatList.filter(item => item.url);;

      this.loading = false;
      setTimeout(() => { $('.achievement-item').height($('.achievement-item').width()) }, 100);
    }
  },
}

</script>

<style scoped lang="scss">
@import "./certificat_list.scss";
</style>
