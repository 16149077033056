import {
  createStore
} from 'vuex'

const store = createStore({
  state() {
    return {
      userId: null,
      userInfo: null,
      storeBooks: [],
      levelInfo: null,
      allAchievementList: [],
      catalogList: [],
      showReturn: false,
      phoneMode: false,
      electronConfig: null,
      column: null,
      routerAbout: false,
      isElectron: false,

      studentVip: null,
      currentStore: null,
      storeBooks: [],

      columnList: []
    }
  },
  mutations: {
    setUserId(state, userId) {
      state.userId = userId;
    },
    userInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    studentVip(state, studentVip) {
      state.studentVip = studentVip;
    },
    storeBooks(state, storeBooks) {
      state.storeBooks = storeBooks;
    },
    levelInfo(state, levelInfo) {
      state.levelInfo = levelInfo;
    },
    allAchievementList(state, allAchievementList) {
      state.allAchievementList = allAchievementList;
    },
    catalogList(state, catalogList) {
      state.catalogList = catalogList;
    },
    showReturn(state, showReturn) {
      state.showReturn = showReturn;
    },
    phoneMode(state, phoneMode) {
      state.phoneMode = phoneMode;
    },
    column(state, column) {
      state.column = column;
    },
    routerAbout(state, routerAbout) {
      state.routerAbout = routerAbout;
    },
    isElectron(state, isElectron) {
      state.isElectron = isElectron;
    },
    electronConfig(state, electronConfig) {
      state.electronConfig = electronConfig;
    },
    setCurrentStore(state, currentStore) {
      state.currentStore = currentStore;
    },
    setStoreBooks(state, storeBooks) {
      state.storeBooks = storeBooks;
    },
    setColumnList(state, columnList) {
      state.columnList = columnList;
    }
  },
  actions: {},
  modules: {}
})

export default store