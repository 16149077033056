<template>
  <div class="audiovisual-list">
    <div class="audiovisual" v-for="(_item, m) of item2.children" :key="m">
      <div class="audiovisual-text" v-if="_item.subType == '文字题干'">
        <div style="width: calc(100%);">{{ _item.text }}</div>
      </div>
      <div class="audiovisual-audio" v-if="_item.subType == '音频题干'">
        <AudioPlayer :item="_item" />
      </div>
      <div class="audiovisual-audio" v-if="_item.subType == '视频题干'">
        <VideoPlayer :item="_item" />
      </div>
      <div v-if="_item.subType == '选项'">
        <div class="audiovisual-option">
          <div style="color: #f78899;">
            [{{ modelObj[_item.mode] }}]
          </div>
          <div style="width: calc(100% - 80px);font-weight: bold;" v-html="_item.text">
          </div>
        </div>
        <div class="audiovisual-answer">
          <template v-if="_item.optionAudio">
            <div class="tool" @click="stopPlayOptionAudio" v-if="isPlayerOptionAudio == m">
              <div class="cancel"></div>
            </div>
            <div class="tool" style="padding-left: 2px;" v-else @click="playOptionAudio(m, _item.optionAudio)">
              <iconpark-icon name="play"></iconpark-icon>
            </div>
          </template>
          <template v-if="stuAnswer && stuAnswer[m] && stuAnswer[m].url">
            <div class="tool" @click="stopPlayRecordAudio" v-if="isPlayerAnswerAudio == m">
              <div class="cancel"></div>
            </div>
            <div class="tool" v-else @click="playRecordAudio(m)">
              <iconpark-icon name="volume-notice"></iconpark-icon>
            </div>
          </template>
        </div>
        <Result :question="_item" />
      </div>
    </div>
    <audio ref="answerAudio" v-show="false"></audio>
    <audio ref="optionAudio" v-show="false"></audio>
  </div>
</template>

<script>
import AudioPlayer from './AudioPlayer.vue'
import VideoPlayer from './VideoPlayer.vue'
import Result from './Result.vue'
import { store } from '@/store'
export default {
  components: {
    AudioPlayer,
    VideoPlayer,
    Result,
  },
  props: {
    stuAnswer: {
      type: Object,
      required: true
    },
    item2: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      modelObj: {
        follow: '跟读',
        answer: '回答'
      },
      isRecordAudio: '',
      isPlayerAnswerAudio: '',
      isPlayerOptionAudio: '',
      mediaRecorder: null,
      baseUrl: 'https://textbook-test1.lessonplan.cn:23789',
    }
  },
  computed: {
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.answerAudio.volume = .5
      this.$refs.answerAudio.addEventListener('ended', () => {
        this.isPlayerAnswerAudio = ''
      })
      this.$refs.optionAudio.volume = .5
      this.$refs.optionAudio.addEventListener('ended', () => {
        this.isPlayerOptionAudio = ''
      })
    })
  },
  methods: {
    playOptionAudio(itemIndex, url) {
      if(this.isPlayerOptionAudio) this.stopPlayRecordAudio()
      this.isPlayerOptionAudio = itemIndex
      const audio = this.$refs.optionAudio
      audio.src = url
      audio.play()
    },
    stopPlayOptionAudio() {
      this.$refs.optionAudio.pause()
      this.isPlayerOptionAudio = ''
    },
    playRecordAudio(itemIndex) {
      if(this.isPlayerOptionAudio) this.stopPlayOptionAudio()
      this.isPlayerAnswerAudio = itemIndex
      const audio = this.$refs.answerAudio
      audio.src = this.stuAnswer[itemIndex].url
      audio.play()
    },
    stopPlayRecordAudio() {
      this.$refs.answerAudio.pause()
      this.isPlayerAnswerAudio = ''
    },
    stopRecordAudio() {
      this.mediaRecorder.stop()
      this.isRecordAudio = ''
    },
  }
}
</script>