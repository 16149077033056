import axios from '../config'
import { DigitalTextbookServer } from '../baseURL'
import { STORE_ID } from '@/config/constant'

export default {
  storeFindOne() {
    return axios.get(`${DigitalTextbookServer}/store/store/${STORE_ID}`)
  },
  storeBookFind() {
    return axios.get(`${DigitalTextbookServer}/storeBook/list?fk_store_id=${STORE_ID}&status=1`)
  },
  storeBookFindOne(bookId) {
    return axios.get(`${DigitalTextbookServer}/storeBook/storeBook/${bookId}`)
  },

  storeClassFindByCode(invitation_code) {
    return axios.get(`${DigitalTextbookServer}/bookClass/findByCode?invitation_code=${invitation_code}`)
  },

  bookUserFindOne(fk_user_id) {
    return axios.get(`${DigitalTextbookServer}/bookUser/one?fk_user_id=${fk_user_id}`)
  },
  bookUserNew(data) {
    return axios.post(`${DigitalTextbookServer}/bookUser/newbookUser`, data)
  },
  bookUserUpdate(data) {
    return axios.put(`${DigitalTextbookServer}/bookUser/bookUser`, data)
  },
  bookUserJoinClass(data) {
    return axios.post(`${DigitalTextbookServer}/bookUser/joinClass`, data)
  },

  getFileContent(url) {
    return axios.get(url)
  }
}