<template>
    <div class="pts-content" v-if="behaviorData">
        <a-spin class="spin-box" :spinning="loading">
            <div class="pts-item">
                <div class="pts-item-title">综合成绩</div>
                <div class="score-list">
                    <div class="score-item" v-for="(item, index) in behaviorData.overallStatisticList" :key="index">
                        <a-progress class="box-progress" :percent="item.percent" type="circle" status="normal"
                            :strokeWidth="8" :width="phoneMode ? 80 : 120" :strokeColor="'#AB3982'">
                            <template #format>
                                <span>{{ item.value }} {{ item.unit }}</span>
                            </template>
                        </a-progress>
                        <div class="score-name">{{ item.name }}</div>
                    </div>
                </div>
            </div>

            <div class="pts-item">
                <div class="pts-item-title">知识图谱</div>
                <div class="pts-item-content knowledgeGraph">
                    <div class="knowledgeGraph-item" v-for="(item, i) in behaviorData.levelGroup" :key="i">
                        <div class="knowledgeGraph-level">{{ i + 1 }}级知识点</div>
                        <div class="knowledgeGraph-nodes">
                            <div class="knowledgeGraph-node" v-for="node in item" :key="node.id"
                                :style="{ backgroundColor: node.studentColor }">
                                {{ node.label }}
                            </div>
                        </div>
                    </div>
                    <a-empty class="empty-box"
                        v-if="!behaviorData.levelGroup || behaviorData.levelGroup.length === 0"></a-empty>
                </div>
            </div>

            <div class="pts-item">
                <div class="pts-item-title">学习轨迹</div>
                <div class="pts-item-content">
                    <PortraitsStudyRecord :inductionList="behaviorData.inductionList"
                        v-if="behaviorData.inductionList?.length > 0">
                    </PortraitsStudyRecord>
                    <a-empty class="empty-box" v-else></a-empty>
                </div>
            </div>

            <div class="pts-item">
                <div class="pts-item-title">错题本</div>
                <div class="pts-item-content pts-item-content-wrong">
                    <PortraitsWrongBook :wrongQuestion="behaviorData.wrongQuestion"
                        v-if="behaviorData.wrongQuestion?.length > 0">
                    </PortraitsWrongBook>
                    <a-empty class="empty-box" v-else></a-empty>
                </div>
            </div>

            <div class="pts-item">
                <div class="pts-item-title">疑问</div>
                <div class="pts-item-content">
                    <PortraitsQuestion :questions="behaviorData.questions" v-if="behaviorData.questions?.length > 0">
                    </PortraitsQuestion>
                    <a-empty class="empty-box" v-else></a-empty>
                </div>
            </div>
        </a-spin>
    </div>
    <div class="empty-box" v-else-if="!loading">
        <img :src="empty_image" alt />
        <div>暂无数据</div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import PortraitsStudyRecord from "./portraits-study-record.vue";
import PortraitsWrongBook from "./portraits-wrong-book.vue";
import PortraitsQuestion from "./portraits-question.vue";

export default {
    components: {
        PortraitsStudyRecord,
        PortraitsWrongBook,
        PortraitsQuestion
    },
    data() {
        return {
            userId: "",
            empty_image: "https://img.lessonplan.cn/IMG/yuanxi/images/empty.png",

            behaviorData: null,
            loading: true,
        }
    },
    props: {
        currentBook: {
            type: Object,
            default: null
        }
    },
    computed: {
        ...mapState({
            phoneMode: (state) => state.phoneMode || false,
        })
    },
    watch: {
        currentBook: {
            handler: function () {
                this.getPortraits();
            }
        }
    },
    async mounted() {
        const { userInfo } = this.$store.state;
        this.userId = userInfo.PK_UserGuid;
        this.getPortraits();
    },
    methods: {
        async getPortraits() {
            this.loading = true;

            const { pk_store_book_id } = this.currentBook;
            const res = await this.$api.unifydata.studentPortraits({
                storeBookId: pk_store_book_id,
                studentIds: [this.userId]
            })
            if (res.code == 200 && res.data) {
                const behaviorData = res.data[0];
                if (behaviorData?.inductionList.length == 1) {
                    behaviorData.inductionList[0].unFoldApp = behaviorData.inductionList[0].appList.length > 0 ? true : false;
                }
                this.behaviorData = behaviorData;
            }
            this.loading = false;
        },
        formatPercent(item) {
            return `${item.value} ${item.unit}`;
        }
    }
}
</script>

<style lang="scss" scoped>
.pts-content {
    position: relative;
    color: #ffffff;
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;

    .pts-item {
        padding: 10px 20px;
        width: 100%;
        background-color: #bbbbbb4f;
        border-radius: 8px;
        margin: 20px 0;

        .pts-item-title {
            font-size: 17px;
            font-weight: bold;
            height: 40px
        }

        .score-list {
            display: flex;
            align-items: center;
            margin: 0 -10px;
            margin-top: 20px;

            .score-item {
                text-align: center;
                width: calc(100%/6);

                .score-name {
                    margin-top: 10px;
                }
            }
        }

        .knowledgeGraph {
            .knowledgeGraph-item {
                margin-bottom: 10px;
                padding: 10px;
                border-radius: 7px;
                border: 1px solid #6c6c829e;
                background-image: linear-gradient(to bottom, #ab398233, #ffb6c11a);

                .knowledgeGraph-level {
                    padding: 0 5px;
                }

                .knowledgeGraph-nodes {
                    display: flex;
                    flex-wrap: wrap;

                    .knowledgeGraph-node {
                        padding: 2px 7px;
                        border-radius: 7px;
                        margin: 3px;
                    }
                }
            }
        }
    }
}

.phone-mode {
    .pts-content {
        .pts-item {
            .score-list {
                flex-wrap: wrap;

                .score-item {
                    width: calc(100%/3);
                    margin-bottom: 40px;
                }
            }
        }
    }
}

.pts-item-content-wrong {
    max-height: 800px;
    overflow-y: auto;
}
</style>
