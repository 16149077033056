<template>
  <div>
    <div class="q-q">
      <div class="q-index">[{{ item2.type }}] </div>
      <div class="q-html fillintheblank" v-html="item2.question"></div>
    </div>
    <div class="opt-list">
      <div class="opt" :class="{ 'select-radio': isStuAnswer(index3) }" v-for="(item3, index3) in item2.opt"
        :key="index3"><span>
          {{ String.fromCharCode(index3 + 65) }}. </span>
        <div v-html="item3.text"></div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  components: {
  },
  props: {
    stuAnswer: {
      type: [String, Number],
      required: true
    },
    item2: {
      type: Object,
      required: true
    },
  },
  computed: {
    radioStyle() {
      return {
        display: 'block',
        lineHeight: '24px',
      }
    },
  },
  mounted() {
  },
  methods: {
    isStuAnswer(index) {
      const arr = String(this.stuAnswer).split(',')
      return arr.includes(index.toString())
    },
    strToArrStr(str) {
      if(str) {
        const array = []
        const array2 = str.split(',')
        for(let i = 0; i < array2.length; i++) {
          array.push(JSON.parse(array2[i]))
        }
        return array
      }
    },
    arrStrToStr(arr) {
      return arr.join(',')
    },
  }
}
</script>
<style lang="scss" scoped>
.opt
{
  display: flex;

  div
  {
    flex: 1;
    margin-left: 10px;
  }
}

:deep(.ant-radio-disabled)
{
  .ant-radio-inner::after
  {
    background-color: #e96c98 !important;
  }
}

:deep(.ant-checkbox-disabled)
{

  .ant-checkbox-inner::after
  {
    border-color: #e96c98 !important;
  }
}
</style>